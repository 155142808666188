var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile)?_c('AppAsideRight',{ref:"myNavDrawer",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('LevelCard',{staticClass:"mx-5 py-6 pb-9 level-card",attrs:{"icon-url":_vm.level.iconUrl,"name":_vm.level.name,"state":_vm.level.state,"task-completed":_vm.level.taskCompleted,"task-count":_vm.level.taskCount}})]},proxy:true}],null,false,1916590397)},[_c('div',{staticClass:"px-5 py-4 task-card__wrapper"},_vm._l((_vm.level.tasks),function(ref){
var id = ref.id;
var type = ref.type;
var name = ref.name;
var durationMin = ref.durationMin;
var bonus = ref.bonus;
var maxBonus = ref.maxBonus;
var isCompleted = ref.isCompleted;
var isRequired = ref.isRequired;
return _c('VCard',{key:id,ref:("taskId" + id),refInFor:true,staticClass:"pa-4 task-card",class:{'task-card__selected' : id === Number(_vm.taskId)},attrs:{"id":("task-" + id),"elevation":"0","to":{name : _vm.Names.R_APP_LEVEL_VIEW, params : {levelId: _vm.levelId, taskId : String(id) }},"ripple":false,"data-test":("task-card-aside-right-" + id)}},[_c('div',{staticClass:"d-flex"},[_c('VBadge',{attrs:{"overlap":"","bordered":"","value":isCompleted,"color":"white"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('VIcon',{attrs:{"color":"tt-light-green vibrant"}},[_vm._v(" fa fa-check-circle ")])]},proxy:true}],null,true)},[_c('VAvatar',{attrs:{"color":"tt-light-blue pale","size":"36"}},[_c('VIcon',{attrs:{"size":"12","color":"tt-light-blue","data-test":"task-card-icon-aside-right"}},[_vm._v(" "+_vm._s(_vm.$options.TASK_TYPE_ICON[type])+" ")])],1)],1),_c('div',{staticClass:"task-card__content ml-5"},[_c('div',{staticClass:"d-flex align-center task-card__title"},[(name)?_c('h4',{staticClass:"tt-text-body-2",attrs:{"data-test":"task-card-name-aside-right"}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('ChipDuration',{staticClass:"mr-2 mt-2",attrs:{"duration":durationMin,"data-test":"task-card-duration-aside-right"}}),_c('ChipBonus',{staticClass:"mt-2",attrs:{"current":bonus,"max":maxBonus,"data-test":"task-card-bonus-aside-right"}}),_c('ChipStatus',{staticClass:"mt-2",attrs:{"status":isRequired ? _vm.$options.REQUIRED : null,"data-test":"task-card-status-aside-right"}})],1)])],1)])}),1)]):_c('VNavigationDrawer',{attrs:{"value":_vm.value,"color":"tt-white","width":"100%","bottom":"","fixed":"","temporary":""}},[_c('LevelCard',{staticClass:"mx-5 py-6 pb-9 level-card",attrs:{"icon-url":_vm.level.iconUrl,"name":_vm.level.name,"state":_vm.level.state,"task-completed":_vm.level.taskCompleted,"task-count":_vm.level.taskCount}}),_c('div',{staticClass:"px-5 py-4 task-card__wrapper"},_vm._l((_vm.level.tasks),function(ref){
var id = ref.id;
var type = ref.type;
var name = ref.name;
var durationMin = ref.durationMin;
var bonus = ref.bonus;
var maxBonus = ref.maxBonus;
var isCompleted = ref.isCompleted;
var isRequired = ref.isRequired;
return _c('VCard',{key:id,ref:("taskId" + id),refInFor:true,staticClass:"pa-4 task-card",class:{'task-card__selected' : id === Number(_vm.taskId)},attrs:{"id":("task-" + id),"elevation":"0","to":{name : _vm.Names.R_APP_LEVEL_VIEW, params : {levelId: _vm.levelId, taskId : String(id) }},"ripple":false,"data-test":("task-card-aside-right-" + id)}},[_c('div',{staticClass:"d-flex"},[_c('VBadge',{attrs:{"overlap":"","bordered":"","value":isCompleted,"color":"white"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('VIcon',{attrs:{"color":"tt-light-green vibrant"}},[_vm._v(" fa fa-check-circle ")])]},proxy:true}],null,true)},[_c('VAvatar',{attrs:{"color":"tt-light-blue pale","size":"36"}},[_c('VIcon',{attrs:{"size":"12","color":"tt-light-blue","data-test":"task-card-icon-aside-right"}},[_vm._v(" "+_vm._s(_vm.$options.TASK_TYPE_ICON[type])+" ")])],1)],1),_c('div',{staticClass:"task-card__content ml-5"},[_c('div',{staticClass:"d-flex align-center task-card__title"},[(name)?_c('h4',{staticClass:"tt-text-body-2",attrs:{"data-test":"task-card-name-aside-right"}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('ChipDuration',{staticClass:"mr-2 mt-2",attrs:{"duration":durationMin,"data-test":"task-card-duration-aside-right"}}),_c('ChipBonus',{staticClass:"mt-2",attrs:{"current":bonus,"max":maxBonus,"data-test":"task-card-bonus-aside-right"}}),_c('ChipStatus',{staticClass:"mt-2",attrs:{"status":isRequired ? _vm.$options.REQUIRED : null,"data-test":"task-card-status-aside-right"}})],1)])],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }