<template>
  <AppAsideRight
    v-if="!isMobile"
    ref="myNavDrawer"
  >
    <template #prepend>
      <LevelCard
        class="mx-5 py-6 pb-9 level-card"
        :icon-url="level.iconUrl"
        :name="level.name"
        :state="level.state"
        :task-completed="level.taskCompleted"
        :task-count="level.taskCount"
      />
    </template>
    <div class="px-5 py-4 task-card__wrapper">
      <VCard
        v-for="{id, type, name, durationMin, bonus, maxBonus, isCompleted, isRequired} in level.tasks"
        :id="`task-${id}`"
        :ref="`taskId${id}`"
        :key="id"
        class="pa-4 task-card"
        :class="{'task-card__selected' : id === Number(taskId)}"
        elevation="0"
        :to="{name : Names.R_APP_LEVEL_VIEW, params : {levelId, taskId : String(id) }}"
        :ripple="false"
        :data-test="`task-card-aside-right-${id}`"
      >
        <div class="d-flex">
          <VBadge
            overlap
            bordered
            :value="isCompleted"
            color="white"
          >
            <template #badge>
              <VIcon color="tt-light-green vibrant">
                fa fa-check-circle
              </VIcon>
            </template>
            <VAvatar
              color="tt-light-blue pale"
              size="36"
            >
              <VIcon
                size="12"
                color="tt-light-blue"
                data-test="task-card-icon-aside-right"
              >
                {{ $options.TASK_TYPE_ICON[type] }}
              </VIcon>
            </VAvatar>
          </VBadge>
          <div class="task-card__content ml-5">
            <div class="d-flex align-center task-card__title">
              <h4
                v-if="name"
                class="tt-text-body-2"
                data-test="task-card-name-aside-right"
              >
                {{ name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <ChipDuration
                class="mr-2 mt-2"
                :duration="durationMin"
                data-test="task-card-duration-aside-right"
              />
              <ChipBonus
                class="mt-2"
                :current="bonus"
                :max="maxBonus"
                data-test="task-card-bonus-aside-right"
              />
              <ChipStatus
                class="mt-2"
                :status="isRequired ? $options.REQUIRED : null"
                data-test="task-card-status-aside-right"
              />
            </div>
          </div>
        </div>
      </VCard>
    </div>
  </AppAsideRight>
  <VNavigationDrawer
    v-else
    :value="value"
    color="tt-white"
    width="100%"
    bottom
    fixed
    temporary
  >
    <LevelCard
      class="mx-5 py-6 pb-9 level-card"
      :icon-url="level.iconUrl"
      :name="level.name"
      :state="level.state"
      :task-completed="level.taskCompleted"
      :task-count="level.taskCount"
    />
    <div class="px-5 py-4 task-card__wrapper">
      <VCard
        v-for="{id, type, name, durationMin, bonus, maxBonus, isCompleted, isRequired} in level.tasks"
        :id="`task-${id}`"
        :ref="`taskId${id}`"
        :key="id"
        class="pa-4 task-card"
        :class="{'task-card__selected' : id === Number(taskId)}"
        elevation="0"
        :to="{name : Names.R_APP_LEVEL_VIEW, params : {levelId, taskId : String(id) }}"
        :ripple="false"
        :data-test="`task-card-aside-right-${id}`"
      >
        <div class="d-flex">
          <VBadge
            overlap
            bordered
            :value="isCompleted"
            color="white"
          >
            <template #badge>
              <VIcon color="tt-light-green vibrant">
                fa fa-check-circle
              </VIcon>
            </template>
            <VAvatar
              color="tt-light-blue pale"
              size="36"
            >
              <VIcon
                size="12"
                color="tt-light-blue"
                data-test="task-card-icon-aside-right"
              >
                {{ $options.TASK_TYPE_ICON[type] }}
              </VIcon>
            </VAvatar>
          </VBadge>
          <div class="task-card__content ml-5">
            <div class="d-flex align-center task-card__title">
              <h4
                v-if="name"
                class="tt-text-body-2"
                data-test="task-card-name-aside-right"
              >
                {{ name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <ChipDuration
                class="mr-2 mt-2"
                :duration="durationMin"
                data-test="task-card-duration-aside-right"
              />
              <ChipBonus
                class="mt-2"
                :current="bonus"
                :max="maxBonus"
                data-test="task-card-bonus-aside-right"
              />
              <ChipStatus
                class="mt-2"
                :status="isRequired ? $options.REQUIRED : null"
                data-test="task-card-status-aside-right"
              />
            </div>
          </div>
        </div>
      </VCard>
    </div>
  </VNavigationDrawer>
</template>

<script>
import AppAsideRight from '@/components/app/AppAsideRight.vue';
import LevelCard from '@/components/level/LevelCard.vue';
import ChipDuration from '@/components/chips/ChipDuration.vue';
import ChipBonus from '@/components/chips/ChipBonus.vue';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import { REQUIRED } from '@/helpers/constants';
import { mapGetters } from 'vuex';
import { TASK_TYPE_ICON } from './constants';

export default {
  name: 'TaskAsideRight',

  TASK_TYPE_ICON,
  REQUIRED,

  components: {
    ChipStatus,
    ChipBonus,
    ChipDuration,
    LevelCard,
    AppAsideRight,
  },

  inject: ['Names'],
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    levelId: {
      type: [String, Number],
      required: true,
    },
    taskId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapGetters('levels', ['levelById']),
    level() {
      return this.levelById(Number(this.levelId));
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    taskId: {
      handler: 'scrollTo',
      immediate: true,
    },
  },
  methods: {
    scrollTo() {
      this.$nextTick(() => {
        try {
          const navDrawerContent = this.$refs.myNavDrawer.$el.querySelector('div.v-navigation-drawer__content');
          this.$vuetify.goTo(this.$refs[`taskId${this.taskId}`][0], { container: navDrawerContent, offset: -30 });
        } catch (e) {
          // TODO Обработчик ошибок
          console.log(e);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.level-card{
  border-bottom: 1px solid map-get($tt-light-mono-8, 'base');
}
::v-deep .v-card--link:focus:before {
  opacity: 0;
}
.task-card__wrapper {
  :first-child{
    border-top-color: map-get($tt-light-mono-0, 'base')!important;
  }
  .task-card {
    border-top: 1px solid map-get($tt-light-mono-8, 'base');
    min-width: 0;
    border-radius: 0;

    .task-card__content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      min-width: 0;

      .task-card__title {
        min-width: 0;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  .task-card__selected {
    border-radius: 16px;
    border-top-color: map-get($tt-light-mono-0, 'base');
    box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
    z-index: 1;
  }

  .task-card__selected + .task-card {
    border-top-color: map-get($tt-light-mono-0, 'base');
  }

  ::v-deep .v-badge__wrapper span {
    height: 14px;
    width: 14px;
    min-width: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .v-badge__badge .v-icon {
    margin: 0;
  }
}
</style>
