export const TASK_TYPE_ICON = {
  WATCH: 'fa fa-play-circle',
  READ: 'fa fa-book',
  TAKE: 'fa fa-hand-point-down',
  LOCATION: 'fa fa-map-marker-alt',
  TEST: 'fa fa-tasks',
  TASK: 'fa fa-glasses',
  MEET: 'fa fa-handshake',
  QRCODE: 'fa fa-qrcode',
  OTHER: 'fa fa-box',
};

export const TASK_COLS = 12;
