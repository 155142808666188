<template>
  <div class="d-flex">
    <VImg
      :src="iconUrl"
      :max-height="imageSize"
      :max-width="imageSize"
      :width="imageSize"
      :height="imageSize"
      data-test="level-card-img"
      data-test-value=""
    />
    <div class="level-card__content ml-5">
      <div class="d-flex align-center level-card__title">
        <h3
          v-if="name"
          :class="headerClasses"
          data-test="level-card-name"
        >
          {{ name }}
        </h3>
        <VIcon
          v-if="isCompleted"
          :color="progressBarColors.main"
          small
          class="ml-1"
          data-test="level-card-icon-completed"
        >
          fa fa-check-circle
        </VIcon>
      </div>
      <div class="d-flex mt-2">
        <slot name="chips" />
      </div>
      <div
        v-if="taskCount"
        class="d-flex mt-auto align-center"
      >
        <VProgressLinear
          :value="progressBar"
          rounded
          :background-color="progressBarColors.bg"
          :color="progressBarColors.main"
          data-test="level-card-progress-linear"
        />
        <div
          class="ml-4 text-no-wrap"
          :class="progressBarTextClasses"
          data-test="level-card-task-count"
        >
          {{ taskCompleted }} {{ $t('common.from') }} {{ taskCount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CLOSED, COMPLETED, OPENED, OPENED_NEXT, LEVEL_CARD_IMAGE_SIZE, TRACK_LEVEL_CARD_IMAGE_SIZE,
} from '@/helpers/constants';

export default {
  name: 'LevelCard',
  props: {
    iconUrl: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    taskCompleted: {
      type: Number,
      default: 0,
    },
    taskCount: {
      type: Number,
      default: 0,
    },
    isTrack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSize() {
      return this.isTrack ? TRACK_LEVEL_CARD_IMAGE_SIZE : LEVEL_CARD_IMAGE_SIZE;
    },
    isClosed() {
      return this.state === CLOSED;
    },
    isOpen() {
      return !this.isClosed && (this.state === OPENED || this.state === OPENED_NEXT);
    },
    isCompleted() {
      return !this.isClosed && this.state === COMPLETED;
    },
    progressBar() {
      if (!this.isClosed) {
        if (!this.taskCompleted) {
          return 1;
        }
        return Math.ceil((100 / this.taskCount) * this.taskCompleted);
      }
      return 0;
    },
    progressBarColors() {
      if (this.isCompleted) {
        return {
          bg: 'tt-light-green vibrant',
          main: 'tt-light-green vibrant',
        };
      }
      if (this.isOpen) {
        return {
          bg: 'tt-light-blue pale',
          main: 'tt-light-blue',
        };
      }
      return {
        bg: 'tt-light-mono-8',
        main: 'tt-light-mono-8',
      };
    },
    headerClasses() {
      return { 'tt-text-title-2': this.isTrack, 'tt-text-subtitle': !this.isTrack };
    },
    progressBarTextClasses() {
      return { 'tt-text-body-2': this.isTrack, 'tt-text-caption': !this.isTrack };
    },
  },
};
</script>

<style lang="scss" scoped>
.level-card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  .level-card__title {
    min-width: 0;
    h3{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
