<template>
  <VNavigationDrawer
    v-bind="{...$attrs,$props}"
    :width="$attrs['width'] || $options.APP_ASIDE_RIGHT_WIDTH"
    color="tt-white"
    app
    permanent
    clipped
    right
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <slot name="default" />
  </VNavigationDrawer>
</template>

<script>
import { APP_ASIDE_RIGHT_WIDTH } from '@/helpers/constants';

export default {
  name: 'AppAsideRight',
  APP_ASIDE_RIGHT_WIDTH,
};
</script>
